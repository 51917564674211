interface IProps {
	title?: string
}
const initialProps: IProps = {
	title: '',
}

export const getMeta = (data: IProps = initialProps): object => {
	const { title } = data
	return {
		title: `Bitclear ${title}`,
		description: 'Your cryptocurrency provider Bitclear',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'Bitclear,bitcoin,cryptocurrency,payment provider,bfx,bpay',
			},
		},
	}
}
