import { CrossLines } from 'assets/icon'
import BfxSection from 'components/pages/home/BfxSection'
import BpaySection from 'components/pages/home/BpaySection'
import InfoSection from 'components/pages/home/InfoSection'
import WhyUsSection from 'components/pages/home/WhyUsSection'
import { getMeta } from 'pages/metaTags'
import { FC } from 'react'
import DocumentMeta from 'react-document-meta'
import { PageContentSpace } from 'ui/Space'

const Home: FC = () => {
	return (
		<>
			<DocumentMeta {...getMeta()} />
			<PageContentSpace padding="7%" />
			<BfxSection />
			<PageContentSpace padding="8%" />
			<CrossLines width="100%" />
			<PageContentSpace padding="4%" />
			<BpaySection />
			<PageContentSpace padding="14%" />
			<WhyUsSection />
			<PageContentSpace padding="8%" />
			<InfoSection />
			<PageContentSpace padding="9%" mobile={{ padding: '15%' }} />
		</>
	)
}

export default Home
