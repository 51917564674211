import Shield from 'assets/image/shield.png'
import Shield2 from 'assets/image/shield@2x.png'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { MobileView } from 'styles/mixins/MobileView'
import { Half } from 'ui/Half'
import { Image } from 'ui/Image'
import { PageContent } from 'ui/PageContent'
import { H2 } from 'ui/Title'

const data = {
	title: "It's completely safe to trade with us.",
	desc: 'We are a European registered company, holding all the necessary licences.',
}

const LeftStyled = styled.div`
	${FontSizeResponsive({ size: 'normal' })}
	text-align: left;

	h2 {
		margin-bottom: 20px;
	}
`
const Wrapper = styled.div`
	.col img {
		${MobileView(css`
			max-width: 70%;
		`)}
	}
`
const Left: FC = () => (
	<LeftStyled>
		<H2 withDash>{data.title}</H2>
		<div>{data.desc}</div>
	</LeftStyled>
)
const Right: FC = () => <Image src={[Shield, Shield2]} alt="" />

const InfoSection: FC = () => {
	return (
		<PageContent>
			<Wrapper>
				<Half left={<Left />} right={<Right />} leftFirstMobile={false} />
			</Wrapper>
		</PageContent>
	)
}

export default InfoSection
