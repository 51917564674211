import { Bfx } from 'assets/icon'
import { UiProductDescription, UiProductImage } from 'components/pages/home/UiProductSection'
import { FC } from 'react'
import styled from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { Half } from 'ui/Half'
import { PageContent } from 'ui/PageContent'
import { Space } from 'ui/Space'

const SubHeader = styled.div`
	${FontSizeResponsive({ size: 'subheader' })};
	font-weight: ${({ theme }) => theme.weight.normal};
`

const Desc: FC = () => (
	<UiProductDescription color={'orange'} title={'Crypto Exchange'}>
		Buy and sell cryptocurrency
		<Space padding={'2%'} />
		<SubHeader>instantly, easy and secure</SubHeader>
	</UiProductDescription>
)
const Image: FC = () => <UiProductImage Svg={Bfx} />

const BfxSection: FC = () => {
	return (
		<PageContent>
			<Half left={<Desc />} right={<Image />} leftFirstMobile={false} />
		</PageContent>
	)
}

export default BfxSection
