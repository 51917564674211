import { Bpay } from 'assets/icon'
import { UiProductDescription, UiProductImage } from 'components/pages/home/UiProductSection'
import { FC } from 'react'
import { Half } from 'ui/Half'
import { PageContent } from 'ui/PageContent'

const Desc: FC = () => (
	<UiProductDescription color={'green'} title={'Payment Gate'}>
		Accept Bitcoin for online payments
	</UiProductDescription>
)
const Image: FC = () => <UiProductImage Svg={Bpay} />

const BpaySection: FC = () => {
	return (
		<>
			<PageContent>
				<Half left={<Image />} right={<Desc />} />
			</PageContent>
		</>
	)
}

export default BpaySection
