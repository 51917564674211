import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { IThemeColors, IThemeSizes, IThemeWeight } from 'styles/themeStyles'

interface ITitle {
	size?: keyof IThemeSizes
	color?: keyof IThemeColors
	weight?: keyof IThemeWeight
	withDash?: boolean
}

const Title = styled.div<ITitle>`
	margin: auto;
	color: ${({ theme, color }) => theme.color[color || 'inherit']};
	${({ size }) => FontSizeResponsive({ size: size || 'header' })}
	line-height: ${({ theme }) => theme.lineHeight.small};
	font-weight: ${({ weight, theme }) => theme.weight[weight || 'extraBold']};

	&:before {
		display: ${({ withDash }) => (withDash ? 'block' : 'none')};
		content: '';
		width: 60px;
		height: 0;
		border-top: 2px solid ${({ theme }) => theme.color.pink};
		margin: 0 0 20px 0;
	}
`

export const H1: FC<ITitle> = (props) => <Title as="h1" {...props} />
export const H2: FC<ITitle & HTMLAttributes<any>> = (props) => <Title as="h2" {...props} />
export const H3: FC<ITitle> = (props) => {
	const size = props.size || 'medium'
	const weight = props.weight || 'bold'
	return <Title as="h3" {...props} size={size} weight={weight} />
}
export const H4: FC<ITitle> = (props) => {
	const size = props.size || 'normal'
	const weight = props.weight || 'normal'
	return <Title as="h4" {...props} size={size} weight={weight} />
}
