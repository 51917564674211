import { FC } from 'react'
import styled from 'styled-components'

const ImageStyled = styled.img`
	display: block;
	max-width: 100%;
	margin: auto;
`

interface IImage {
	src: string | string[] // [pathToSize1, pathToSize@2x]
	alt?: string
}
export const Image: FC<IImage> = ({ src: path, alt = '' }) => {
	let src: string = path as string
	let src2: string | undefined = undefined

	if (path.constructor === Array) {
		src = path[0]
		src2 = path[1]
	}
	const srcPath = src2 ? src2 : src
	let srcSet: undefined | string = undefined
	if (src2) {
		srcSet = `${src}, ${src2} 2x`
	}

	return <ImageStyled src={srcPath} srcSet={srcSet} alt={alt} />
}

export const WrappedImage: FC<IImage> = (props) => (
	<div className="img">
		<Image {...props} />
	</div>
)
