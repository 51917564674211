import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'

interface IWrapper {
	leftFirstMobile: boolean
	hideColumnMobile?: 'left' | 'right'
	proportion: '1-1' | '3-4'
}
const Wrapper = styled.div<IWrapper>`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex: 1 0 auto;

	.col {
		${({ proportion }) =>
			proportion === '1-1'
				? css`
						width: 50%;
				  `
				: css`
						width: 70%;

						&:last-child {
							width: 30%;
						}
				  `}
	}

	${({ leftFirstMobile, hideColumnMobile }) =>
		MobileView(
			css`
				flex-direction: ${leftFirstMobile ? 'column' : 'column-reverse'};

				.col {
					width: 100%;

					&:last-child {
						width: 100%;
						padding: ${leftFirstMobile ? '5% 0 0 0' : '0 0 5% 0'};
					}
				}
				// hide column
				${hideColumnMobile &&
				(hideColumnMobile === 'left'
					? css`
							.col:first-child {
								display: none;
							}
					  `
					: css`
							.col:last-child {
								display: none;
							}
					  `)}
			`,
			{ screen: 'lg' },
		)};
`

type IProps = Partial<IWrapper> & {
	left: ReactElement
	right: ReactElement
}
export const Half: FC<IProps> = ({ left = null, right = null, leftFirstMobile = true, proportion = '1-1' }) => (
	<Wrapper leftFirstMobile={leftFirstMobile} proportion={proportion} className="half">
		<div className="col">{left}</div>
		<div className="col">{right}</div>
	</Wrapper>
)
