import { Diamond, Lock, Rocket, Worldwide } from 'assets/icon'
import { FC } from 'react'

import { PATH } from 'Routes'
import styled, { css } from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { MobileView } from 'styles/mixins/MobileView'
import { themeStyles } from 'styles/themeStyles'
import { InternalButtonLink } from 'ui/Button'
import { PageContent } from 'ui/PageContent'
import { Space } from 'ui/Space'
import { H2, H4 } from 'ui/Title'

const Wrapper = styled.div`
	text-align: center;
	padding: 10% 0 11% 0;

	${MobileView(css`
		padding-bottom: 100px;
	`)}
`
const SubTitle = styled.div`
	${FontSizeResponsive({ size: 'small' })}
	font-weight: ${({ theme }) => theme.weight.normal};
`
const Items = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;

	${MobileView(
		css`
			flex-direction: column;
			align-items: center;
		`,
		{ screen: 'xs' },
	)}
`
const Image = styled.div`
	border: 2px solid ${({ theme }) => theme.color.white};
	border-radius: 40px;
	width: 160px;
	height: 160px;
	display: flex;
	margin: 0 auto 39px auto;
	justify-content: center;
	align-items: center;
	${MobileView(css`
		width: 110px;
		height: 110px;
		border-radius: 25px;
		margin-bottom: 24px;
	`)}

	svg {
		font-size: 3.8rem;
		fill: ${({ theme }) => theme.color.white};
	}
`
const Item = styled.div`
	width: 160px;
	margin: 0 10px 50px 0;
	overflow: hidden;
	color: white;

	${MobileView(
		css`
			min-width: 40%;
		`,
		{ screen: 'md' },
	)}
	${MobileView(css`
		margin: 0 auto 50px auto;
	`)}	
	

	h4 {
		line-height: ${({ theme }) => theme.lineHeight.medium};
		font-weight: ${({ theme }) => theme.weight.bold};
		height: calc(2 * (${({ theme }) => theme.lineHeight.medium} * ${({ theme }) => theme.size.normal}));
		margin-bottom: 20px;

		${MobileView(css`
			height: auto;
		`)}
	}
`

const items: { title: string; subTitle: string; Icon: FC }[] = [
	{
		title: 'Best Prices',
		subTitle: 'Buy cryptocurrencies at the best market price - no hidden fees.',
		Icon: Diamond,
	},
	{
		title: 'Safe and Secure',
		subTitle: 'We execute your transaction with state of art technology.',
		Icon: Lock,
	},
	{
		title: 'Instant Transfer',
		subTitle: 'Get your cryptocurrency instantly - 24/7.',
		Icon: Rocket,
	},
	{
		title: 'Trusted Worldwide',
		subTitle: 'Strictly regulated and licensed in Liechtenstein',
		Icon: Worldwide,
	},
]

const WhyUsSection: FC = () => {
	return (
		<PageContent background={themeStyles.color.blueDark}>
			<Wrapper>
				<H2 color={'white'}>Why us?</H2>
				<Space padding={'8%'} mobile={{ padding: '15%' }} />
				<Items>
					{items.map(({ Icon, title, subTitle }, index) => (
						<Item key={index}>
							<Image>
								<Icon />
							</Image>
							<H4>{title}</H4>
							<SubTitle>{subTitle}</SubTitle>
						</Item>
					))}
				</Items>
				<Space padding={'7%'} />
				<InternalButtonLink to={PATH.ABOUT_US} type={'default'} size={'small'} ghost>
					MORE ABOUT US
				</InternalButtonLink>
			</Wrapper>
		</PageContent>
	)
}

export default WhyUsSection
