import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { FontSizeResponsive, FontSizeResponsiveStatic } from 'styles/mixins/FontSizeResponsive'
import { MobileView } from 'styles/mixins/MobileView'
import { IThemeColors } from 'styles/themeStyles'

interface IDescriptionStyled {
	color: keyof IThemeColors
}
const DescriptionStyled = styled.div<IDescriptionStyled>`
	text-align: left;
	${FontSizeResponsive({ size: 'header' })};
	font-weight: ${({ theme }) => theme.weight.extraBold};
	padding-right: 1%;
	line-height: ${({ theme }) => theme.lineHeight.small};

	.title {
		${FontSizeResponsive({ size: 'big' })};
		color: ${({ theme, color }) => theme.color[color]};
		padding: 0 0 20px 0;
	}
`
interface IDescription extends IDescriptionStyled {
	title: ReactElement | string
}
export const UiProductDescription: FC<IDescription> = ({ children, color, title }) => (
	<DescriptionStyled color={color}>
		<h2 className={'title'}>{title}</h2>
		{children}
	</DescriptionStyled>
)
interface IImage {
	Svg: FC
}
export const UiProductImage: FC<IImage> = ({ Svg }) => {
	const SvgStyled = styled(Svg)`
		${FontSizeResponsiveStatic({ size: '21em', mobileSize: '9em' })};
		max-width: 100%;

		${MobileView(
			css`
				display: block;
			`,
			{ screen: 'sm' },
		)}
	`
	return <SvgStyled />
}
